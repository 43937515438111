<template>
  <component :is="view"></component>
</template>

<script>
import Welcome from "./Welcome.vue";
import Menu from "./Menu.vue";

import { mapGetters } from "vuex";

export default {
  name: "view-default",
  components: {
    Welcome,
    Menu,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    view() {
      return this.user ? "Menu" : "Welcome";
    },
  },
};
</script>
