<template>
  <section class="w-full md:w-1/3 lg:w-1/3 p-5 mx-auto">
    <!--  Header.  -->
    <h1 class="title mb-4"><slot name="title"></slot></h1>
    <!--  Main.  -->
    <div>
      <slot name="content"></slot>
      <Footer />
    </div>
  </section>
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  name: "DefaultLayout",
  components: {
    Footer,
  },
};
</script>
