<template>
  <div>
    <default-layout>
      <template #title>
        <div class="d-flex align-center justify-between mx-10">
          <div>Menu</div>
          <v-btn small plain @click="$store.dispatch('auth/signOut')">
            Log out <v-icon right>mdi-logout</v-icon>
          </v-btn>
        </div>
      </template>
      <template #content>
        <div class="flex flex-col space-y-4">
          <nav-button to="/submit-result">Add Indicator Results</nav-button>
          <nav-button to="/results" outline>List of Results</nav-button>
          <nav-button to="/profile" outline>User Profile</nav-button>
          <nav-button v-if="isAdmin" to="/users" outline>Manage Users</nav-button>
          <nav-button to="/clinic" outline>Clinic Information</nav-button>
          <nav-button to="/sterilizers" outline>Manage Sterilizers</nav-button>
          <nav-button v-if="isAdmin" to="/audit-trail" outline>Audit Trail</nav-button>
        </div>
        <router-view />
      </template>
    </default-layout>
    <modal ref="newUserModal">
      <div class="flex flex-column m-6">
        <p>
          Before you can save results with this service please add the following info to the Account Profile:
          <ul>
            <li>Username / Full Name</li>
            <li>Clinic / Dentist (Business Name)</li>
            <li>Sterilizers + Cycles</li>
            <li>Email address (to send results)</li>
          </ul>
        </p>
        <base-button @click="confirmModal()" primary>Continue</base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { BaseButton, Modal, NavButton } from "@/components";
import DefaultLayout from "@/layouts/DefaultLayout";
import { mapGetters } from "vuex";

export default {
  name: "Menu",
  components: { DefaultLayout, BaseButton, Modal, NavButton },
  methods: {
    closeModal() {
      localStorage.setItem("isNewUser", "false");
      this.$refs.newUserModal.closeModal();
    },
    confirmModal() {
      this.$store.dispatch("userData/setDefaultUserData", this.user)
        .then(() => this.$router.push("/profile"))
        .then(() => this.closeModal());
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      admins: "clinic/admins"
    }),
    isAdmin() {
      return this.admins.includes(this.user.uid);
    }
  },
  mounted() {
    const isNewUser = localStorage.getItem("isNewUser");

    if (isNewUser === "true") {
      this.$refs.newUserModal.openModal();
    }
  }
};
</script>
