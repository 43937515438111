<template>
  <section class="md:w-1/3 p-5 mx-auto">
    <div id="header">
      <a href="https://tuttnauer.com" target="_blank">
        <img
          src="../assets/images/tuttnauer-logo.png"
          alt=""
          class="h-20 mx-auto"
        />
      </a>
      <img src="../assets/images/drop.png" alt="" class="mx-auto" />
      <img
        src="../assets/images/logo.png"
        alt=""
        class="header__logo mx-auto"
      />
      <div
        class="header__decription uppercase font-bold text-xl text-center mt-8"
      >
        20 SECOND<br />
        EXPRESS SPORE TEST
      </div>
    </div>
    <div class="mx-8 mt-16 pb-2">
      <nav-button to="/signin" outline>Login</nav-button>
      <nav-button to="/signup" outline>Sign-UP</nav-button>
    </div>
    <div class="fixed bottom-0 right-0 w-full bg-white">
      <p class="text-sm text-center">
        Copyright © {{ new Date().getFullYear() }}
      </p>
    </div>
  </section>
</template>

<script>
import NavButton from "@/components/buttons/NavButton.vue";

export default {
  name: "Welcome",
  components: {
    NavButton,
  },
};
</script>
